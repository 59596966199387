import { useEffect } from "react";

export const useResetDownloadModalStep = <
  TState extends { openModal: boolean },
  TAction extends { type: any; payload: any },
>(
  state: TState,
  dispatchReducer: React.Dispatch<TAction>,
  action: TAction["type"],
) => {
  useEffect(() => {
    if (!state.openModal) {
      dispatchReducer({
        type: action,
        payload: 1,
      } as TAction);
    }
  }, [state.openModal]);
};
