import React, { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Stack } from "@mui/material";

import { Text } from "@ui/typography";

import { useIsDesktop } from "@hooks/layout";

import { ERoutes } from "@utils/constants";

import { Button, Link } from "@/ui-kit";

import * as Styled from "../styles";
import { THandleNextStepProp } from "../utils";

type FinalProps = {
  onClose: () => void;
  routes: { first: ERoutes; second?: ERoutes };
};

export const Final: FC<Pick<THandleNextStepProp, "type"> & FinalProps> = ({ type, onClose, routes }) => {
  const { t: tCaption } = useTranslation("questionFlow", { keyPrefix: `finalSteps` });
  const isDesktop = useIsDesktop();

  const { t } = useTranslation(isDesktop ? "questionFlow" : "questionFlowMobile", { keyPrefix: `finalSteps.${type}` });
  const navigate = useNavigate();

  const handleClose = (route: ERoutes) => {
    onClose();
    setTimeout(() => navigate(route), 300);
  };

  return (
    <Styled.ContentWrapper alignItems={"center"}>
      <Text isHeading size={isDesktop ? 1.5 : 1.125} isFullWidth textAlign="center" lineHeight={140}>
        <Trans i18nKey={"title"} t={t} />
      </Text>
      <Styled.StyledDivider sx={{ margin: isDesktop ? "28px auto 24px" : "16px auto" }} />
      <Text isFullWidth size={isDesktop ? 1 : 0.875} textAlign="center" mb={isDesktop ? "32px" : "16px"}>
        <Trans
          i18nKey={"description"}
          t={t}
          components={
            type === "livestreams"
              ? [
                  <Link
                    component="link"
                    target="_self"
                    size={"inherit"}
                    onClick={() => handleClose(ERoutes.MUSIC_CREATOR_PROGRAM)}
                  />,
                ]
              : []
          }
        />
      </Text>
      {routes.second ? (
        <Stack
          width={"100%"}
          direction={isDesktop ? "row" : "column"}
          justifyContent="space-between"
          sx={{ mb: "32px" }}
          gap={isDesktop ? "24px" : "16px"}
        >
          <Button
            isBigButton
            onClick={() => handleClose(routes.first)}
            sx={{ fontSize: "0.875rem", width: `100% !important` }}
          >
            {t("firstButtonText")}
          </Button>
          <Button
            isBigButton
            onClick={() => handleClose(routes.second)}
            sx={{ fontSize: "0.875rem", width: `100% !important` }}
          >
            {t("secondButtonText")}
          </Button>
        </Stack>
      ) : (
        <Button
          isBigButton
          isFullWidth
          onClick={() => handleClose(routes.first)}
          sx={{ mb: `${isDesktop ? "32px" : "16px"}` }}
        >
          {t("buttonText")}
        </Button>
      )}
      <Text isFullWidth textAlign="center" size={0.875} color="#fff">
        <Trans
          t={tCaption}
          i18nKey="caption"
          components={{ contactLink: <Link href={ERoutes.Contact} target="_self" /> }}
        />
      </Text>
    </Styled.ContentWrapper>
  );
};
