import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getUserDownloads, limitDownloadsAmount } from "../../actions/downloads";
import { TSetOptimisticDownloadCountPayload } from "./types";

export type DownloadsState = {
  downloadsLimit?: number;
  albumsCount?: number;
  tracksCount?: number;
};

const initialState: DownloadsState = {};

const { actions, reducer } = createSlice({
  name: "downloads",
  initialState,
  reducers: {
    setOptimisticDownloadCount: (state, action: PayloadAction<TSetOptimisticDownloadCountPayload>) => {
      const { payload } = action;

      if (payload.isAlbum) {
        state.albumsCount = state.albumsCount + 1;
      }
      state.tracksCount = state.tracksCount + 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(limitDownloadsAmount.fulfilled, (state, { payload }) => {
      state.downloadsLimit = payload;
    });
    builder.addCase(getUserDownloads.fulfilled, (state, { payload }) => {
      state.albumsCount = payload.albumDownloadCount;
      state.tracksCount = payload.trackDownloadCount;
    });
  },
});

export const { setOptimisticDownloadCount } = actions;
export default reducer;
