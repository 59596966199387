import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink as BaseNavLink } from "react-router-dom";

import { Box, Grid, Stack } from "@mui/material";

import { Button as GradientButton, IconButton } from "@ui/index";
import { Link } from "@ui/interactive";

import { ERoutes } from "@utils/constants";

import { ShopLogoIcon } from "@/assets/svg";
import {
  FacebookLogo,
  GoldenAppleMusicLogo,
  GoldenBandcampLogo,
  GoldenSoundCloudLogo,
  GoldenSpotifyLogo,
  GoldenYouTubeLogo,
  InstagramLogo,
  TwitterLogo,
} from "@/assets/svg/Links";
import { IconButtonTypography } from "@/components/IconButtonLinks/components";
import { useIsDesktop } from "@/hooks/layout";

import { appleMusic, bandcamp, soundcloud, spotify, youtube } from "./constants";
import { FooterIconMenu } from "./Menu";

interface IIconButtonLinks {
  isFooter?: boolean;
}

const IconButtonLinks: FC<IIconButtonLinks> = ({ isFooter }) => {
  const { t } = useTranslation("navigation");
  const isDesktop = useIsDesktop();

  if (isFooter) {
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center" width="100%" rowSpacing="40px">
        <Grid item xs={12} md={3} order={isDesktop ? 1 : 2}>
          <Stack justifyContent="center" alignItems="center" spacing={isDesktop ? "16px" : "8px"}>
            {isDesktop && <IconButtonTypography>{t("socials")}</IconButtonTypography>}
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={isDesktop ? "24px" : "16px"}>
              <BaseNavLink to={ERoutes.FaceBook} target="_blank">
                <IconButton isFooter>
                  <FacebookLogo />
                </IconButton>
              </BaseNavLink>

              <BaseNavLink to={ERoutes.Instagram} target="_blank">
                <IconButton isFooter>
                  <InstagramLogo />
                </IconButton>
              </BaseNavLink>

              <BaseNavLink to={ERoutes.Twitter} target="_blank">
                <IconButton isFooter>
                  <TwitterLogo />
                </IconButton>
              </BaseNavLink>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={3} order={isDesktop ? 2 : 3}>
          <Stack justifyContent="center" alignItems="center" spacing={isDesktop ? "16px" : "8px"}>
            {isDesktop && <IconButtonTypography>{t("iconsTitles.streaming")}</IconButtonTypography>}
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={isDesktop ? "24px" : "16px"}>
              <FooterIconMenu title={youtube.title} isFooter links={youtube.links}>
                <GoldenYouTubeLogo />
              </FooterIconMenu>

              <FooterIconMenu title={spotify.title} isFooter links={spotify.links}>
                <GoldenSpotifyLogo />
              </FooterIconMenu>

              <FooterIconMenu title={soundcloud.title} isFooter links={soundcloud.links}>
                <GoldenSoundCloudLogo />
              </FooterIconMenu>

              <Link href={bandcamp} target={"_blank"}>
                <IconButton isFooter>
                  <GoldenBandcampLogo />
                </IconButton>
              </Link>

              <Link href={appleMusic} target={"_blank"}>
                <IconButton isFooter>
                  <GoldenAppleMusicLogo />
                </IconButton>
              </Link>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={3} order={isDesktop ? 3 : 1}>
          <Stack justifyContent="center" alignItems="center" spacing={isDesktop ? "16px" : "8px"}>
            {isDesktop && <IconButtonTypography>{t("iconsTitles.purchase")}</IconButtonTypography>}
            <GradientButton
              isOutlined
              isFullWidth={!isDesktop}
              width={152}
              onClick={() => window.open(ERoutes.Shop, "_blank").focus()}
            >
              <BaseNavLink to={ERoutes.Shop} style={{ textDecoration: "none", color: "inherit" }} target="_blank">
                <Stack direction="row" justifyContent="center" alignItems="center" spacing="8px">
                  <ShopLogoIcon width={24} height={18} />
                  <Box>{t(`shopMerchandise.${isDesktop ? "goToShop" : "goToShopMobile"}`)}</Box>
                </Stack>
              </BaseNavLink>
            </GradientButton>
          </Stack>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" width="100%" rowSpacing="40px">
      <Grid item xs={12} md={4}>
        <Stack justifyContent="center" alignItems="center" spacing={isDesktop ? "16px" : "8px"}>
          <IconButtonTypography>{t("socials")}</IconButtonTypography>

          <Stack direction="row" justifyContent="center" alignItems="center" spacing={{ xs: "16px", lg: "24px" }}>
            <BaseNavLink to={ERoutes.FaceBook} target="_blank">
              <IconButton isBigSize>
                <FacebookLogo />
              </IconButton>
            </BaseNavLink>

            <BaseNavLink to={ERoutes.Instagram} target="_blank">
              <IconButton isBigSize>
                <InstagramLogo />
              </IconButton>
            </BaseNavLink>

            <BaseNavLink to={ERoutes.Twitter} target="_blank">
              <IconButton isBigSize>
                <TwitterLogo />
              </IconButton>
            </BaseNavLink>
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12} md={4}>
        <Stack justifyContent="center" alignItems="center" spacing={isDesktop ? "16px" : "8px"}>
          <IconButtonTypography>{t("iconsTitles.streaming")}</IconButtonTypography>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={{ xs: "16px", lg: "24px" }}>
            <FooterIconMenu title={youtube.title} isBigSize links={youtube.links}>
              <GoldenYouTubeLogo />
            </FooterIconMenu>

            <FooterIconMenu title={spotify.title} isBigSize links={spotify.links}>
              <GoldenSpotifyLogo />
            </FooterIconMenu>

            <FooterIconMenu title={soundcloud.title} isBigSize links={soundcloud.links}>
              <GoldenSoundCloudLogo />
            </FooterIconMenu>

            <Link href={bandcamp} target={"_blank"}>
              <IconButton isFooter>
                <GoldenBandcampLogo />
              </IconButton>
            </Link>

            <Link href={appleMusic} target={"_blank"}>
              <IconButton isFooter>
                <GoldenAppleMusicLogo />
              </IconButton>
            </Link>
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12} md={4}>
        <Stack justifyContent="center" alignItems="center" spacing={isDesktop ? "16px" : "8px"}>
          {isDesktop && <IconButtonTypography>{t("iconsTitles.shop")}</IconButtonTypography>}

          <Stack width={isDesktop ? "50%" : "100%"} justifyContent="center" alignItems="center">
            <GradientButton
              isOutlined
              isBigButton
              onClick={() => window.open(ERoutes.Shop, "_blank").focus()}
              width={206}
            >
              <BaseNavLink to={ERoutes.Shop} style={{ textDecoration: "none", color: "inherit" }} target="_blank">
                <Stack direction="row" justifyContent="center" alignItems="center" spacing="8px">
                  <ShopLogoIcon width={24} height={18} />
                  <Box>{t("shopMerchandise.goToShop")}</Box>
                </Stack>
              </BaseNavLink>
            </GradientButton>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default IconButtonLinks;
