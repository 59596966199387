import { FC, MutableRefObject, PropsWithChildren } from "react";

import { ERoutes } from "@utils/constants";

import * as Styled from "./styles";

type TNavLink = {
  to: ERoutes;
  close?: () => void;
  isFooter?: boolean;
  isDisabled?: boolean;
  end?: boolean;
  noLineAnimation?: boolean;
  navElems?: MutableRefObject<HTMLElement[]>;
} & PropsWithChildren &
  Partial<Pick<HTMLAnchorElement, "target" | "id" | "onclick">>;

export const NavLink: FC<TNavLink> = ({
  isFooter = false,
  to,
  children,
  isDisabled = false,
  end = false,
  noLineAnimation = false,
  close,
  navElems,
  ...restProps
}) => {
  return (
    <Styled.NavLink
      to={to}
      $isFooter={isFooter}
      $isDisabled={isDisabled}
      $noLineAnimation={noLineAnimation}
      onClick={(e) => {
        if (isDisabled) {
          e.preventDefault();
        }
        if (close) {
          close();
        }
      }}
      end={end}
      ref={(el) => {
        if (!navElems || !el) {
          return;
        }
        navElems.current[navElems.current.length] = el;
      }}
      {...restProps}
    >
      {children}
    </Styled.NavLink>
  );
};
