import React, { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Box, Stack } from "@mui/material";

import { InfoLink } from "@components/InfoStack/components";

import AudioSanctumLogo from "@/assets/svg/logos/smal-logo_ico.svg";
import GradientDialog from "@/components/Dialog";
import { DialogTitleTypography, HaveAnAccountTypography, Logo } from "@/components/LoginDialog/components";
import { useIsDesktop } from "@/hooks/layout";
import { Link } from "@/ui-kit";

import Form from "./Form";

interface ILoginDialog {
  open: boolean;
  handleClose: () => void;
  handleOpenRecoveryDialog: () => void;
  isLogin: boolean;
  handleChange: () => void;
  isClosable: boolean;
}

const LoginDialog: FC<ILoginDialog> = ({
  open,
  handleClose,
  handleOpenRecoveryDialog,
  isLogin,
  handleChange,
  isClosable,
}) => {
  const { t } = useTranslation("login");

  const isDesktop = useIsDesktop();

  return (
    <GradientDialog open={open} handleClose={handleClose} isClosable={isClosable} isNotScrollable={true}>
      <Stack height="100%" width="100%">
        <Stack spacing={isDesktop ? "40px" : "24px"} justifyContent="center" alignItems="center">
          <Stack spacing="16px" justifyContent="center" alignItems="center">
            <Logo src={AudioSanctumLogo} alt="Logo" loading="lazy" />
            <DialogTitleTypography>{t(isLogin ? "accountLogin" : "createAccount")}</DialogTitleTypography>
          </Stack>

          <Stack spacing={isDesktop ? "24px" : "16px"} width="100%" justifyContent="center" alignItems="center">
            <Form isSignUp={!isLogin} handleClose={handleClose} handleOpenRecoveryDialog={handleOpenRecoveryDialog} />
          </Stack>
        </Stack>
        <Stack justifyContent="center" alignItems="center">
          {isLogin ? (
            <Stack
              direction="row"
              width="90%"
              spacing="16px"
              marginTop={isDesktop ? "40px" : "24px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <InfoLink underline="hover" onClick={handleChange}>
                {t("createAccountButton")}
              </InfoLink>
              <Box sx={{ background: "#fff", width: "1px", height: "16px", transform: "scaleX(0.85)" }}></Box>
              <InfoLink underline="hover" onClick={handleOpenRecoveryDialog}>
                {t("forgotPassword")}
              </InfoLink>
            </Stack>
          ) : (
            <HaveAnAccountTypography style={{ marginTop: "24px" }}>
              <Trans
                t={t}
                i18nKey="haveAnAccount"
                components={{ linkToLogin: <Link component="button" size={"inherit"} onClick={handleChange} /> }}
              />
            </HaveAnAccountTypography>
          )}
        </Stack>
      </Stack>
    </GradientDialog>
  );
};

export default LoginDialog;
