import React, { ChangeEvent, forwardRef, KeyboardEvent } from "react";
import { useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextFieldProps } from "@mui/material";

import { CloseIcon } from "@assets/svg";

import { ERoutes } from "@utils/constants";

import { IconButton } from "@/ui-kit";

import * as Styled from "./styles";

type TSearchTextField = TextFieldProps & {
  value: string;
  handleChange: (value: string, event?: ChangeEvent<HTMLInputElement>) => void;
};

export const SearchTextField = forwardRef<HTMLInputElement, TSearchTextField>((props, ref) => {
  const { value, handleChange, ...restProps } = props;

  const navigate = useNavigate();
  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      navigate(ERoutes.Search, {
        state: {
          searchString: value,
        },
      });
    }
  };
  return (
    <Styled.TextField
      fullWidth
      ref={ref}
      value={value}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        handleChange(event.target.value, event);
      }}
      onKeyDown={onKeyDown}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {value.length !== 0 && (
              <IconButton isTransparent onClick={() => handleChange("")}>
                <CloseIcon width="16px" height="16px" />
              </IconButton>
            )}
          </InputAdornment>
        ),
        startAdornment: (
          <InputAdornment position="start">
            {value.length === 0 && <SearchIcon sx={{ color: "textColor.subtitle" }} />}
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
});
