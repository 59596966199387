import React, { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Grid, Link, Stack, Typography } from "@mui/material";

import { CookiesAnchor } from "@layouts/components/SecondFooter/styles";
import { ERoutes } from "@utils/constants";

import { FOOTER_LOGO } from "@/assets/svg";
import { StyledDivider } from "@/components/components";
import IconButtonLinks from "@/components/IconButtonLinks";
import { useIsDesktop } from "@/hooks/layout";
import { CopyrightTypography } from "@/layouts/components/components";
import {
  AudioSanctumDescription,
  AudioSanctumTypography,
  NavigationTypography,
  NewsLetterTypography,
} from "@/layouts/components/SecondFooter/components";
import Dialog from "@/pages/HomePage/components/JoinOurNewsletter/components/Dialog";
import { NavLink } from "@/ui-kit";

import Form from "./Form";

interface ISecondFooter {
  isNewsletter?: boolean;
}

const SecondFooter: FC<ISecondFooter> = ({ isNewsletter = true }) => {
  const { t } = useTranslation("navigation");
  const isDesktop = useIsDesktop();

  const [openDialog, setOpenDialog] = useState(false);

  const handleCookieClock = () => {
    document.getElementById("open_preferences_center").click();
  };

  return (
    <>
      <Stack sx={{ background: "linear-gradient(180deg, #59412E 0%, #503B2A 100%)" }} width="100%" position="relative">
        <StyledDivider $isFooter />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          paddingTop={isDesktop ? "64px" : "48px"}
          paddingX={{ xs: "16px", md: "40px", lg: "250px" }}
          paddingBottom="16px"
          gap={isDesktop ? "80px 40px" : "40px 0"}
          margin="0 auto"
          maxWidth={"xl"}
        >
          <Grid item xs={12} md="auto">
            <Stack width="100%" justifyContent="center" alignItems={{ xs: "center", md: "start" }} spacing="25px">
              <Stack spacing="24px" direction="row" alignItems="center">
                <FOOTER_LOGO width={isDesktop ? "80px" : "64px"} height={isDesktop ? "80px" : "64px"} />
                {isDesktop && (
                  <Stack>
                    <Typography
                      sx={{
                        color: "#AC8D75",
                        fontSize: "18px",
                        fontFamily: "Kaisei Opti",
                      }}
                    >
                      AUDIO SANCTUM®
                    </Typography>
                    <Typography
                      sx={{
                        color: "#AC8D75",
                        fontSize: "10px",
                        fontFamily: "Kaisei Opti",
                        letterSpacing: "3px",
                      }}
                    >
                      MUSIC FOR YOUR SOUL
                    </Typography>
                  </Stack>
                )}
              </Stack>

              <Stack
                width="100%"
                justifyContent="center"
                alignItems={{ xs: "center", md: "start" }}
                spacing={{ xs: "16px", md: "16px" }}
              >
                {isNewsletter ? (
                  <>
                    <NewsLetterTypography>{t(isDesktop ? "newsletter" : "newsletterMobile")}</NewsLetterTypography>
                    <Form handleOpenDialog={() => setOpenDialog(true)} />
                  </>
                ) : (
                  <>
                    <AudioSanctumTypography>{t("audioSanctum")}</AudioSanctumTypography>
                    <AudioSanctumDescription>{t("audioSanctumInformation")}</AudioSanctumDescription>
                  </>
                )}
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={7} md="auto" alignSelf="end">
            <Stack spacing={isDesktop ? "24px" : "16px"} justifyContent="center">
              <NavigationTypography>{t(isDesktop ? "legal.title" : "mobile.access")}</NavigationTypography>

              {isDesktop ? (
                <Stack spacing="16px">
                  <NavLink isFooter to={ERoutes.Conditions}>
                    {t("legal.terms")}
                  </NavLink>
                  <NavLink isFooter to={ERoutes.Privacy}>
                    {t("legal.privacy")}
                  </NavLink>
                  <CookiesAnchor onClick={handleCookieClock}>{t("legal.cookies")}</CookiesAnchor>
                </Stack>
              ) : (
                <Stack spacing="16px">
                  <NavLink isFooter to={ERoutes.Deals}>
                    {t("mobile.deals")}
                  </NavLink>
                  <NavLink isFooter to={ERoutes.MUSIC_CREATOR_PROGRAM}>
                    {t("mobile.faq")}
                  </NavLink>
                  <NavLink isFooter to={ERoutes.DownloadInstruction}>
                    {t("mobile.download")}
                  </NavLink>
                  <NavLink isFooter to={ERoutes.Submit}>
                    {t("mobile.submit")}
                  </NavLink>
                  <NavLink isFooter to={ERoutes.Contact}>
                    {t("mobile.contact")}
                  </NavLink>
                </Stack>
              )}
            </Stack>
          </Grid>

          <Grid item xs={5} md="auto" alignSelf="end">
            <Stack spacing={isDesktop ? "24px" : "16px"}>
              <NavigationTypography>{isDesktop ? t("contact.title") : t("mobile.legal")}</NavigationTypography>

              {isDesktop ? (
                <Stack spacing="16px">
                  <NavLink isFooter to={ERoutes.MUSIC_CREATOR_PROGRAM}>
                    {t("contact.musicCreator")}
                  </NavLink>
                  <NavLink isFooter to={ERoutes.Contact}>
                    {t("contact.contact")}
                  </NavLink>
                  <NavLink isFooter to={ERoutes.Submit}>
                    {t("contact.submit")}
                  </NavLink>
                </Stack>
              ) : (
                <Stack spacing="16px">
                  <NavLink isFooter to={ERoutes.FreeUseTerms}>
                    {t("mobile.freeUseTerms")}
                  </NavLink>
                  <NavLink isFooter to={ERoutes.Licensing}>
                    {t("mobile.licensing")}
                  </NavLink>
                  <NavLink isFooter to={ERoutes.Conditions}>
                    {t("mobile.terms")}
                  </NavLink>
                  <NavLink isFooter to={ERoutes.Privacy}>
                    {t("mobile.privacy")}
                  </NavLink>
                  <CookiesAnchor onClick={handleCookieClock}>{t("mobile.cookies")}</CookiesAnchor>
                </Stack>
              )}
            </Stack>
          </Grid>

          <Grid item xs={6} md="auto" alignSelf="end" display={isDesktop ? "block" : "none"}>
            <Stack
              spacing={isDesktop ? "24px" : "16px"}
              justifyContent="center"
              alignItems={{ xs: "center", md: "start" }}
            >
              <NavigationTypography textAlign="center">{t("resources.title")}</NavigationTypography>

              <Stack spacing="16px" justifyContent="center" alignItems={{ xs: "center", md: "start" }}>
                <NavLink isFooter to={ERoutes.FreeUseTerms}>
                  {t("resources.freeUseTerms")}
                </NavLink>
                <NavLink isFooter to={ERoutes.Deals}>
                  {t("resources.deals")}
                </NavLink>
                <NavLink isFooter to={ERoutes.DownloadInstruction}>
                  {t("resources.downloadInstructions")}
                </NavLink>
              </Stack>
            </Stack>
          </Grid>

          <Stack width="100%" alignItems="center" justifyContent="center" spacing={isDesktop ? "56px" : "44px"}>
            <IconButtonLinks isFooter />
            <CopyrightTypography>
              <Trans t={t} i18nKey="copyright" components={[<Link href={ERoutes.Imprint} />]} />
            </CopyrightTypography>
          </Stack>
        </Grid>
      </Stack>

      <Dialog open={openDialog} handleClose={() => setOpenDialog(false)} />
    </>
  );
};

export default SecondFooter;
