import { NavLink as BaseNavLink } from "react-router-dom";

import { css, styled } from "@mui/material";

import { isSmallDesktop } from "@hooks/layout";

type TNavLink = {
  $isFooter: boolean;
  $isDisabled: boolean;
  $noLineAnimation?: boolean;
};

export const NavLink = styled(BaseNavLink)<TNavLink>`
  text-decoration: none;
  font-size: 1rem;
  letter-spacing: 0.01rem;
  text-transform: capitalize;
  line-height: 135%;
  font-family: Raleway;
  font-weight: 400;

  text-decoration-thickness: 1px;
  text-decoration-skip-ink: auto;
  text-underline-offset: 2px;

  color: ${({ theme, $isFooter }) =>
    $isFooter ? theme.palette.textColor.highlighted : theme.palette.header.textColor};

  transition: color 250ms ease-in-out;

  &:hover {
    text-decoration-line: ${({ $isFooter }) => ($isFooter ? "underline" : "none")};
  }

  ${({ $isFooter, theme, $noLineAnimation }) =>
    !$isFooter &&
    `
      path {
        fill: ${theme.palette.header.textColor};
      }

      &.active {
        color: ${theme.palette.secondary.light};

        &:after {
          border-bottom: none;
        }
      }

      &:after {
        display: block;
        content: "";
        border-bottom: ${!$noLineAnimation && `solid 1px ${theme.palette.secondary.light}`};
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
      }

      &:hover {
        transition: fill 250ms ease-in-out;
        color: ${theme.palette.secondary.light};

        path {
          fill: ${theme.palette.secondary.light};
        }
      }

      &:hover:after {
        transform: scaleX(1);
      }
    `}
  ${({ $isDisabled, theme }) =>
    $isDisabled &&
    css`
      color: ${theme.palette.textColor.subtitle};
    `}
    ${isSmallDesktop} {
    font-size: ${({ $isFooter }) => ($isFooter ? "0.9rem" : "1.125rem")};
  }
`;
