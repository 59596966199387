import { TTrack } from "@store/actions/track";

import { ETermsType } from "@/models/inner-models";

export enum ELocalStoreKeys {
  TOKEN = "accessToken",
  REFRESH_TOKEN = "refreshToken",
}

interface ISocialLinks {
  youtube: string;
  facebook: string;
  instagram: string;
  tiktok: string;
  business: string;
}

interface IDownloadCount {
  downloadCount?: number;
}

export interface IUser {
  _id: string;
  email: string;
  firstName: string;
  hasNewsletter: boolean;
  hasGeneralNewsletter: boolean;
  socialLinks: ISocialLinks;
  isEmailVerified: boolean;
  hasAcceptedTerms: boolean;
  hasWav: boolean;
  categories: string[];
  useCases: string[];
  lastChangePassword: Date;
  avatarUrl: string;
  albumDownload?: IDownloadCount;
  trackDownload?: IDownloadCount;
}

export interface ICategories {
  _id: string;
  category: string;
}

export interface IFilters {
  [key: string]: any;
}

export interface ITags {
  _id: string;
  tagTypeId: { _id: string; name: string };
  value: string;
}
export interface IComposer {
  createdAt: string;
  name: string;
  status: string;
  updatedAt: string;
  __v: number;
  _id: string;
}

export interface ISummary {
  composer: string[];
  bpm: number[];
  tags: ITags[];
  length: number;
}

export interface ICatalogue {
  _id: string;
  imageUrl: string;
  name: string;
  description: string;
  shortDescription: string;
  tracks: TTrack[];
  createdAt: string;
  updatedAt: string;
  coverUrl: string;
  lowQualityZipUrl: string;
  zipUrl: string;
  summary: ISummary;
  videoUrl: string;
  numTracks: number;
}

export interface IFavorites {
  trackId: string;
  albumId: string;
}

export interface ICatalogueFilters {
  maxLength: number;
  maxBpm: number;
  composers: string[];
}

export interface ITerms {
  [ETermsType.HEADER]: string;
  [ETermsType.GLOBAL]: string;
  [ETermsType.POPUP]: string;
  [ETermsType.GDPR]: string;
  [ETermsType.COOKIES]: string;
}

export type TFilters = {
  sort?: Record<string, unknown>;
  name: string;
};
