import { FC, PropsWithChildren, useEffect, useState } from "react";

import { AccordionDetails, AccordionSummary, Stack } from "@mui/material";

import { ArrowDownIcon } from "@assets/svg";

import * as Styled from "./styles";

type TFilterSelection = {
  title: string;
  defaultExpanded?: boolean;
  onExpand?: (...any) => any;
} & PropsWithChildren;

export const FilterSelection: FC<TFilterSelection> = ({ title, children, defaultExpanded, onExpand }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    if (onExpand) {
      onExpand();
    }
  };

  useEffect(() => {
    if (isExpanded) {
      handleExpand();
    }
  }, [isExpanded]);

  return (
    <Styled.Accordion
      TransitionProps={{ unmountOnExit: true }}
      defaultExpanded={defaultExpanded}
      onChange={(_, expanded) => setIsExpanded(expanded)}
    >
      <AccordionSummary expandIcon={<ArrowDownIcon />}>
        <Styled.AccordionTitle>{title.toUpperCase()}</Styled.AccordionTitle>
      </AccordionSummary>

      <AccordionDetails>
        <Stack>{children}</Stack>
      </AccordionDetails>
    </Styled.Accordion>
  );
};
