import React, { FC } from "react";

import { Box, Stack } from "@mui/material";

import { CopyrightIcon, DownloadGoldIcon, LicenseRequestIcon, LikeFilledIcon, LikeOutlinedIcon } from "@assets/svg";

import { useWindowSize } from "@hooks/layout";

import * as Styled from "@components/BottomPlayer/styles";
import { EWaveSurferPlayer, getSkeleton, TSkeletons } from "@components/BottomPlayer/utils";
import { ERoutes } from "@utils/constants";
import { formatTimePlayer } from "@utils/formatTimePlayer";

import { IconButton, PlayButton } from "@/ui-kit";

import { AvailableFor, TPlayerProps } from "../types";

export const MobileLayout: FC<TPlayerProps> = ({
  state,
  bottomTrackData,
  handleNavigate,
  handleLicensing,
  handleNavigateComposer,
  handleDownload,
  onPlayClick,
  currentTimeRef,
  containerRef,
  isAvailableFor,
  dispatchReducer,
  handleLike,
}) => {
  const { width } = useWindowSize();
  return (
    <>
      <Stack width="inherit" direction="row" alignItems="center">
        {state.isLoading ? (
          getSkeleton(TSkeletons.PlayButton, false)
        ) : (
          <PlayButton isMobileBottomPlayer onClick={onPlayClick} isPlaying={state.isPlaying} />
        )}
        <Stack direction="row" alignItems="center" gap="4px" margin="0 0 0 12px" width="100%">
          <Styled.TimeDuration width="fit-content" maxWidth="36px" minWidth="36px" ref={currentTimeRef}>
            {state.isLoading ? getSkeleton(TSkeletons.TIME) : "00:00"}
          </Styled.TimeDuration>

          <Styled.WaveFormStack width="100%" $isCurrent>
            {state.isLoading && getSkeleton(TSkeletons.LineProgress, true)}
            <Box
              id="waveform"
              ref={containerRef}
              width="100%"
              height="100%"
              sx={{
                display: state.isLoading ? "none" : "block",
                fontFamily: "Kantumruy Pro !important",
              }}
            />
          </Styled.WaveFormStack>
          <Styled.TimeDuration width="fit-content">
            {state.isLoading ? getSkeleton(TSkeletons.TIME) : formatTimePlayer(bottomTrackData?.length)}
          </Styled.TimeDuration>
        </Stack>
      </Stack>
      <Stack width="inherit" direction="row" alignItems="center" justifyContent="space-between">
        <Stack spacing="4px" maxWidth="40%">
          <Styled.SongTitle
            onClick={() => {
              handleNavigate(ERoutes.Track, bottomTrackData?._id);
            }}
          >
            {state.isLoading ? getSkeleton(TSkeletons.TITLE) : bottomTrackData?.name}
          </Styled.SongTitle>
          <Styled.SongAuthor
            onClick={() =>
              handleNavigateComposer(
                typeof bottomTrackData?.composer[0] === "object"
                  ? bottomTrackData?.composer[0].name
                  : bottomTrackData?.composer[0],
              )
            }
          >
            {state.isLoading
              ? getSkeleton(TSkeletons.COMPOSER)
              : bottomTrackData?.composer.map(
                  (c, i) =>
                    `${c.name ? c.name : c}${
                      bottomTrackData?.composer.length !== 1 && i !== bottomTrackData?.composer.length ? "," : ""
                    }`,
                )}
          </Styled.SongAuthor>
        </Stack>
        <Stack direction="row" spacing="8px">
          <IconButton
            isTransparent
            isBigSize={width > 375}
            isHeartIcon
            isBottomPlayer
            onClick={handleLike}
            isActive={state.isFavorite}
          >
            {state.isFavorite ? (
              <LikeFilledIcon width="32px" height="32px" />
            ) : (
              <LikeOutlinedIcon width="32px" height="32px" />
            )}
          </IconButton>
          {isAvailableFor(AvailableFor.FREE_USE) ? (
            <IconButton isBigSize={width > 375} onClick={handleDownload}>
              <DownloadGoldIcon width="22px" height="24px" />
            </IconButton>
          ) : (
            <IconButton isBigSize={width > 375} onClick={handleLicensing}>
              <LicenseRequestIcon width="22px" height="24px" />
            </IconButton>
          )}
          <IconButton
            isBigSize={width > 375}
            onClick={() => {
              dispatchReducer({
                type: EWaveSurferPlayer.IsCopyright,
                payload: true,
              });
              dispatchReducer({
                type: EWaveSurferPlayer.OpenModal,
                payload: !state.openModal,
              });
            }}
          >
            <CopyrightIcon width="24px" height="24px" />
          </IconButton>
          {state.isLoading ? (
            getSkeleton(TSkeletons.AlbumCover, true)
          ) : (
            <Styled.AlbumCover
              onClick={() => {
                handleNavigate(ERoutes.Album, bottomTrackData?.albumId, true);
              }}
              alt="Album cover"
              src={bottomTrackData?.coverUrl}
              $width={width > 375 ? 48 : 32}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};
