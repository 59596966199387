import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Logout } from "@mui/icons-material";
import { Box, Divider, IconButton, ListItemIcon, MenuItem, MenuList, Stack, Typography } from "@mui/material";

import { DonationButton } from "@components/DonationButton";
import { LicenseTestButton } from "@components/LicenseTestButton";
import { QuestionFlowDialog } from "@components/QuestionFlowDialog";
import { popover } from "@layouts/components/Popover";
import { userMenuNavItems } from "@layouts/constants";
import { DropdownNavItem } from "@layouts/types";
import { ERoutes } from "@utils/constants";
import { bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";

import { FavoritesIcon, SettingsIcon } from "@/assets/svg";
import { useAuth, useLogOut } from "@/hooks/auth";
import { useIsDesktop } from "@/hooks/layout";
import { ProfileAvatar } from "@/pages/Profile/components";
import { handleCloseOnKeyDown, Menu } from "@/ui-kit";

import { UserActionsStyle } from "./components";

const AccountMenu = () => {
  const { t } = useTranslation("navigation");

  const { myData } = useAuth();

  const isDesktop = useIsDesktop();
  const [openQuestionFlow, setOpenQuestionFlow] = useState<boolean>(false);

  const popupState = usePopupState({ variant: "popper", popupId: "account-menu-popover" });

  const { logoutUser, resetUser } = useLogOut();

  const navigate = useNavigate();

  const handleDonationClick = () => {
    document.getElementById("donation_popup_button").click();
  };

  const handleNavigate = (route: ERoutes) => {
    popupState.close();
    setTimeout(() => {
      navigate(route);
    }, 500);
  };

  const renderUserMenuNavItems = ({ key, route }: DropdownNavItem) => (
    <Typography
      sx={{
        width: "fit-content",
        fontFamily: "Raleway",
        fontSize: "1rem !important",
        fontWeight: "400",
        color: "textColor.common",
        lineHeight: "20px",
        letterSpacing: "0.01em",
        minHeight: "32px",
        padding: "6px 0",
        cursor: "pointer",

        "&:hover": {
          color: "textColor.highlighted",

          path: {
            fill: "textColor.highlighted",
          },
        },
      }}
      onClick={() => handleNavigate(route)}
    >
      {t(key)}
    </Typography>
  );

  return (
    <>
      <IconButton size="small" sx={{ padding: 0, justifyContent: "space-around" }} {...bindTrigger(popupState)}>
        <ProfileAvatar
          sx={{
            width: `40px`,
            height: `40px`,
            fontSize: `1.25rem`,
            "&:hover": {
              borderColor: "#ffdca8",
              backgroundColor: "#5C412A",
            },
          }}
          src="#"
        >
          <Box>{myData?.firstName[0].toUpperCase()}</Box>
        </ProfileAvatar>
      </IconButton>
      <Menu popupState={popupState} offset={[0, 27]} paperProps={{ ...popover, width: "265px" }} placement="bottom-end">
        <MenuList
          autoFocus={popupState.isOpen}
          sx={{ padding: 0 }}
          onKeyDown={(event) => handleCloseOnKeyDown(event, popupState)}
        >
          {!isDesktop && (
            <MenuItem
              sx={{
                color: "textColor.common",
                minHeight: "32px !important",
                marginLeft: "14px",
                marginRight: "14px",

                "&:hover": {
                  color: "textColor.header",
                  borderRadius: "6px",
                  backgroundColor: "#5C412A",
                },
              }}
              onClick={() => handleNavigate(ERoutes.Favorites)}
            >
              <ListItemIcon>
                <FavoritesIcon />
              </ListItemIcon>
              {t("favorites")}
            </MenuItem>
          )}
          <Stack padding={"16px"} gap={"16px"}>
            <Stack gap={"6px"} alignItems={"center"}>
              <Typography
                sx={{
                  textTransform: "capitalize",
                  fontFamily: "Raleway",
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  letterSpacing: "0.01em",
                  color: "textColor.subtitle",
                }}
              >
                {myData?.firstName}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontSize: "14px",
                  fontWeight: "300",
                  lineHeight: "12px",
                  letterSpacing: "0.01em",
                  color: "textColor.subtitle",
                }}
              >
                {myData?.email}
              </Typography>
            </Stack>
            <Stack width="100%" gap="8px">
              <DonationButton
                handler={() => handleDonationClick()}
                isFullWidth={true}
                sx={{ fontSize: "0.75rem", padding: "4px !important" }}
              >
                {t("mobile.buttons.donation")}
              </DonationButton>
              <LicenseTestButton
                handler={() => setOpenQuestionFlow(true)}
                isFullWidth={true}
                sx={{ fontSize: "0.75rem", padding: "12px" }}
              >
                {t("mobile.buttons.test")}
              </LicenseTestButton>
            </Stack>

            <Stack width="100%" gap="10px" padding={"0 0 0 2px"}>
              {userMenuNavItems.map(renderUserMenuNavItems)}
            </Stack>

            <Divider
              sx={{
                borderColor: "textColor.subtitle",
                width: "100%",
              }}
            />
            <Stack gap={"8px"} padding={"0 0 0 2px"}>
              <Stack>
                <Box
                  sx={{
                    ...UserActionsStyle,
                  }}
                  onClick={() => handleNavigate(ERoutes.Profile)}
                >
                  <ListItemIcon sx={{ width: "28px", minWidth: "0 !important" }}>
                    <SettingsIcon />
                  </ListItemIcon>
                  {t("settings")}
                </Box>
              </Stack>
              <Box
                sx={{
                  ...UserActionsStyle,
                }}
                onClick={() => {
                  logoutUser();
                  resetUser();
                  handleNavigate(ERoutes.Home);
                }}
              >
                <ListItemIcon sx={{ width: "28px", minWidth: "0 !important" }}>
                  <Logout
                    sx={{
                      width: "20px",
                      color: "textColor.common",
                    }}
                    fontSize="small"
                  />
                </ListItemIcon>
                {t("logOut")}
              </Box>
            </Stack>
          </Stack>
        </MenuList>
      </Menu>
      <QuestionFlowDialog
        open={openQuestionFlow}
        onClose={() => {
          setOpenQuestionFlow(false);
        }}
      />
    </>
  );
};

export default AccountMenu;
