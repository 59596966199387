import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Box, Divider, Stack, Typography } from "@mui/material";

import { Button as GradientButton, Link } from "@ui/index";
import { Text } from "@ui/typography";

import { TAlbum } from "@store/actions/track";

import { DonationButton } from "@components/DonationButton";
import { ERoutes } from "@utils/constants";
import { scrollToAnchor } from "@utils/scrollToAnchor";

import { CopyIcon, LicenseTestShield } from "@/assets/svg";
import CreditText from "@/components/CreditText";
import { useIsDesktop } from "@/hooks/layout";
import { useIsClicked } from "@/hooks/useIsClicked";
import { creditText } from "@/utils/creditText";

import { CircledTitle } from "./styles";

interface ICopyPage {
  item?: TAlbum;
  composer: string;
  videoUrl: string;
  spotifyUrl: string;
  copyrightModal?: boolean;
  openQuestionFlowDialog: () => void;
}

const CopyPage: React.FC<ICopyPage> = ({
  item,
  composer,
  videoUrl = "",
  spotifyUrl = "",
  copyrightModal = false,
  openQuestionFlowDialog,
}) => {
  const { t } = useTranslation("downloadModal");
  const isDesktop = useIsDesktop();

  const { isClicked, handleClick } = useIsClicked();

  const handleDonationClick = () => {
    document.getElementById("donation_popup_button").click();
  };

  const minMaxWidth = isDesktop ? "calc(50% - 24px / 2)" : "100%";

  return (
    <Stack justifyContent="center" alignItems="center">
      <Typography
        variant={isDesktop ? "h3" : "h5"}
        textAlign="center"
        sx={{
          fontWeight: 500,
          color: "textColor.header",
          fontFamily: "Kaisei Opti",
          marginBottom: "24px",
        }}
      >
        {copyrightModal ? t("copyrightStep") : t("secondStep")}
      </Typography>

      {!copyrightModal && (
        <Text size={0.875} color={"#74C56D"} margin={"0 0 16px 0"}>
          {t("successfulDownload")}
        </Text>
      )}

      {!isDesktop && (
        <GradientButton
          isOutlined
          isFullWidth
          onClick={() => openQuestionFlowDialog()}
          sx={{
            paddingInline: "0 !important",
            padding: "10px 16px !important",
            height: "fit-content !important",
            margin: "0 0 16px 0",
            border: "2px solid #5F5146 !important",
            "&::before": {
              background: "transparent !important",
            },
            "&::after": {
              background: "transparent !important",
            },
            background: "transparent !important",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px", justifyContent: "center", color: "inherit" }}>
            <LicenseTestShield />
            <Text size={0.625} sx={{ maxWidth: "100% !important", fontWeight: 500, fontFamily: "Kaisei Opti" }}>
              {t("testButton")}
            </Text>
          </Box>
        </GradientButton>
      )}

      <Box sx={{ width: "100%", display: "flex", gap: "24px", flexDirection: isDesktop ? "row" : "column" }}>
        <Stack
          sx={{
            maxWidth: minMaxWidth,
            minWidth: minMaxWidth,
            minHeight: "238px",
            border: "double 1px transparent",
            borderRadius: "8px",
            background: "#63442D66",
          }}
        >
          <Stack width={"100%"} padding="16px" alignItems={"center"} gap={"16px"}>
            <CircledTitle borderColor={"#6C5646"}>{t("required")}</CircledTitle>

            <Typography
              textAlign="center"
              sx={{
                fontSize: "20px",
                fontWeight: 500,
                color: "textColor.common",
                fontFamily: "Kaisei Opti",
              }}
            >
              {t("addCreditText")}
            </Typography>
            <Typography
              textAlign="center"
              sx={{
                maxWidth: `100%`,
                fontSize: "14px",
                fontWeight: 400,
                color: "textColor.common",
                fontFamily: "Raleway",
                lineHeight: "150%",
              }}
            >
              {t("creditText")}
            </Typography>

            <Divider
              sx={{
                width: "100%",
                borderColor: "#635041",
              }}
            />

            <Typography
              variant="h1"
              sx={{
                fontSize: `12px`,

                textAlign: "center",
                lineHeight: "150%",
              }}
              fontWeight={400}
              color="textColor.common"
            >
              {item ? (
                <CreditText
                  composer={composer}
                  songName={item.name}
                  videoUrl={videoUrl}
                  spotifyUrl={spotifyUrl}
                  sx={{ fontSize: "12px !important", wordBreak: "break-all" }}
                />
              ) : (
                <CreditText
                  composer={composer.split(" - ")[0]}
                  songName={composer.split(" - ")[1]}
                  videoUrl={videoUrl}
                  spotifyUrl={spotifyUrl}
                  sx={{ fontSize: "12px !important", wordBreak: "break-all" }}
                />
              )}
            </Typography>

            <GradientButton
              isOutlined
              isFullWidth
              onClick={() => {
                handleClick();
                setTimeout(() => {
                  scrollToAnchor("donation-block", { block: "start" });
                }, 500);

                if (item) {
                  return navigator.clipboard.writeText(creditText(`${composer} - ${item.name}`, videoUrl));
                }
                navigator.clipboard.writeText(creditText(composer, videoUrl));
              }}
              sx={{ padding: "12px 0", height: "fit-content !important", fontSize: "0.875rem" }}
            >
              <Stack direction="row" spacing="8px" justifyContent="center" alignItems="center">
                <CopyIcon />
                <Box>{t(isClicked ? "clicked" : "clickToCopy")}</Box>
              </Stack>
            </GradientButton>
          </Stack>
        </Stack>

        <Stack
          bgcolor="#1b9c93"
          maxWidth={minMaxWidth}
          minWidth={minMaxWidth}
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          id={"donation-block"}
        >
          <Stack width={"100%"} height={"100%"} padding="16px" alignItems={"center"} gap={"16px"}>
            <CircledTitle borderColor={"#61C1BC"}>{t("optional")}</CircledTitle>
            <Text size={1.25} isHeading>
              {t("voluntaryHeader")}
            </Text>
            <Text size={0.875} isFullWidth color="rgb(255 247 237)" textAlign="center">
              <Trans
                t={t}
                i18nKey={"voluntaryDescription"}
                components={[<Link size="inherit" target={"_self"} href={ERoutes.FreeUseTerms} />]}
              ></Trans>
            </Text>
            <Text size={0.875} fontWeight={500} isFullWidth color="rgb(255 247 237)" textAlign="center">
              {t("thanks")}
            </Text>
            <Text size={0.75} isFullWidth color="rgb(255 247 237)" textAlign="center" margin={"8px 0 0 0"}>
              <Trans
                t={t}
                i18nKey={"note"}
                components={[<Link size="inherit" target="_blank" href={ERoutes.Shop} />]}
              ></Trans>
            </Text>
            <DonationButton
              isFullWidth
              handler={() => handleDonationClick()}
              sx={{ margin: "auto 0 0 0", fontSize: "0.875rem" }}
            >
              {t("voluntaryButton")}
            </DonationButton>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default CopyPage;
