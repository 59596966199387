import { FC, PropsWithChildren } from "react";

import { handleCloseOnKeyDown, IconButton, Menu } from "@ui/interactive";
import { Link } from "@ui/interactive";
import { TIconButton } from "@ui/interactive/IconButton/IconButton";
import { Text } from "@ui/typography";

import choir from "@assets/jpg/Footer/audio-choir-sanctum.jpg";
import chant from "@assets/png/Footer/chant-sanctum.png";
import piano from "@assets/png/Footer/piano-sanctum.png";
import soundtrack from "@assets/png/Footer/soundtrack-sanctum.png";

import { bindTrigger, usePopupState } from "material-ui-popup-state/hooks";

import { ImageWrapper, ItemsWrapper, MenuItemImage, MenuItemWrapper, MenuWrapper } from "./components";
import { LinkType } from "./types";

type FooterIconMenuProps = {
  links: LinkType[];
  title: string;
};

export const FooterIconMenu: FC<PropsWithChildren & FooterIconMenuProps & TIconButton> = ({
  links,
  isFooter = true,
  title,
  children,
  ...restProps
}) => {
  const popupState = usePopupState({ variant: "popper", popupId: "song-menu-popover" });

  const imgs = {
    soundtrack: soundtrack,
    piano: piano,
    choir: choir,
    chant: chant,
  };

  const itemsRender = ({ title, src, type }: LinkType) => (
    <Link href={src} target={"_blank"}>
      <MenuItemWrapper sx={{ color: "textColor.common" }}>
        <ImageWrapper>
          <MenuItemImage src={imgs[type]} />
        </ImageWrapper>
        <Text size={1}>{title}</Text>
      </MenuItemWrapper>
    </Link>
  );

  return (
    <>
      <IconButton {...bindTrigger(popupState)} isFooter={isFooter} {...restProps}>
        {children}
      </IconButton>
      <Menu popupState={popupState} offset={[0, 8]} placement="top-start">
        <MenuWrapper autoFocus={popupState.isOpen} onKeyDown={(event) => handleCloseOnKeyDown(event, popupState)}>
          <Text
            size={0.75}
            fontWeight={700}
            color={"#3C2D23"}
            maxWidth={"100% !important"}
            textAlign={"center"}
            sx={{ backgroundColor: "textColor.highlighted", padding: "8px" }}
          >
            {title}
          </Text>
          <ItemsWrapper>{links.map(itemsRender)}</ItemsWrapper>
        </MenuWrapper>
      </Menu>
    </>
  );
};
