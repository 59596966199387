import styled from "styled-components";

export const InputWrapper = styled.div`
  margin-bottom: 48px !important;

  .container {
    width: fit-content;
    height: fit-content;
    gap: 16px;
  }

  .character {
    width: 48px;
    height: 48px;

    font-size: 2.25rem;
    line-height: 130%;
    font-family: "Kantumruy Pro";
    font-weight: 300;

    border-radius: 8px;

    background-color: #453426;

    color: ${({ theme }) => theme.palette.textColor.common};

    border: 2px solid #ffdca8;
  }

  .character-selected {
    outline: 1px solid ${({ theme }) => theme.palette.textColor.header};

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: "";
      display: block;
      height: 65%;
      width: 1px;
      background: #fff;
      animation: blink 1.25s infinite steps(1, start);
    }

    @keyframes blink {
      0%,
      70% {
        opacity: 1;
      }
      71%,
      100% {
        opacity: 0;
      }
    }
  }
`;
