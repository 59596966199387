import { FC, ReactNode, SyntheticEvent } from "react";

import { Accordion, AccordionDetails, SxProps } from "@mui/material";

import { ArrowDownIcon } from "@assets/svg";

import { AccordionDescription, AccordionTitle, StyledAccordionSummary } from "@/components/Accordion/components";

interface IStyledAccordion {
  expanded: string | false;
  title: string | ReactNode;
  description: string | ReactNode;
  panel: string;
  handleChange: (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => void;
  sx?: SxProps;
  accordionSx?: SxProps;
  withoutMargin?: boolean;
}

const StyledAccordion: FC<IStyledAccordion> = ({
  description,
  title,
  panel,
  expanded,
  handleChange,
  sx,
  accordionSx,
  withoutMargin,
}) => {
  return (
    <Accordion
      expanded={expanded === panel}
      onChange={handleChange(panel)}
      sx={{
        border: expanded === panel ? "1px solid #BA905C !important" : "1px solid #635041 !important",
        ...accordionSx,
      }}
    >
      <StyledAccordionSummary
        expandIcon={<ArrowDownIcon />}
        aria-controls={`${panel}a-content`}
        id={`${panel}a-header`}
        withoutMargin={withoutMargin}
      >
        <AccordionTitle>{title}</AccordionTitle>
      </StyledAccordionSummary>

      <AccordionDetails>
        <AccordionDescription sx={sx}>{description}</AccordionDescription>
      </AccordionDetails>
    </Accordion>
  );
};

export default StyledAccordion;
