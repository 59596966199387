import { FC, ReactNode, useEffect, useRef, useState } from "react";

import { Box, Stack, SxProps } from "@mui/material";

import { isIOS } from "@components/BottomPlayer/components/Player/helpers";

import { useIsDesktop } from "@/hooks/layout";
import { CloseButton } from "@/ui-kit";

import { CustomDialog, ScrollWrapper, StyledBackDrop } from "./components";

interface IGradientDialog {
  open: boolean;
  children: ReactNode;
  handleClose: () => void;
  scroll?: "body" | "paper";
  isClosable?: boolean;
  background?: string;
  btn?: JSX.Element;
  closeBtnStyles?: SxProps;
  leftRightPadding?: string;
  topBottomPadding?: string;
  isNotScrollable?: boolean;
  scrollHeight?: string;
}

const GradientDialog: FC<IGradientDialog> = ({
  open,
  children,
  handleClose,
  scroll,
  isClosable = true,
  background,
  btn,
  closeBtnStyles,
  leftRightPadding,
  topBottomPadding,
  isNotScrollable,
  scrollHeight,
}) => {
  const isDesktop = useIsDesktop();

  const thumbRef = useRef<HTMLElement>(null);
  const thumbHeight = useRef(0);
  const contentContainerRef = useRef<HTMLElement>(null);

  const [isAccessible, setIsAccessible] = useState(false);

  const updateThumbPosition = () => {
    const contentContainer = contentContainerRef.current;

    const contentScrollHeight = contentContainer.scrollHeight;
    const contentClientHeight = contentContainer.clientHeight;

    const thumbHeightPx = (contentClientHeight / contentScrollHeight) * contentClientHeight;

    thumbHeight.current = thumbHeightPx;

    thumbRef.current.style.height = `${thumbHeightPx}px`;
  };
  const handleScroll = () => {
    const contentContainer = contentContainerRef.current;

    const scrollableHeight = contentContainer.scrollHeight - contentContainer.clientHeight;

    if (scrollableHeight <= 0) return;

    const scrollTopPx = contentContainer.scrollTop;

    const maxThumbTopPx = contentContainer.clientHeight - thumbHeight.current;

    const thumbTopPx = Math.min(Math.max((scrollTopPx / scrollableHeight) * maxThumbTopPx, 0), maxThumbTopPx);

    thumbRef.current.style.top = `${thumbTopPx}px`;
  };

  useEffect(() => {
    if (contentContainerRef.current && isAccessible) {
      contentContainerRef.current.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", updateThumbPosition);
      updateThumbPosition();
    }
    if (!open && contentContainerRef.current) {
      contentContainerRef.current.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateThumbPosition);
    }
  }, [isAccessible, open]);

  return (
    <CustomDialog open={open} BackdropComponent={StyledBackDrop} scroll={scroll}>
      <Stack
        sx={{
          background: background
            ? background
            : "linear-gradient(to right bottom, rgba(51,38,28,1) 0%, rgba(79,57,38,1) 100%)",
          padding: "5px",
        }}
        width="100%"
      >
        {isClosable && (
          <Box width="100%" position="relative" zIndex={10}>
            <Box position="absolute" sx={{ top: 0, right: 0, transform: "translate(-15%, 15%)", ...closeBtnStyles }}>
              <CloseButton onClick={handleClose} />
            </Box>
          </Box>
        )}
        {isIOS() && !isNotScrollable ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingTop: topBottomPadding ? topBottomPadding : isDesktop ? "40px" : "24px",
              paddingBottom: topBottomPadding ? topBottomPadding : isDesktop ? "40px" : "24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <ScrollWrapper
                ref={(ref: HTMLDivElement | null) => {
                  setIsAccessible(!!ref);
                  contentContainerRef.current = ref;
                }}
                sx={{
                  paddingLeft: leftRightPadding ? leftRightPadding : isDesktop ? "40px" : "24px",
                  paddingRight: leftRightPadding ? leftRightPadding : isDesktop ? "40px" : "24px",
                  "&&": {
                    maxHeight: scrollHeight,
                  },
                }}
              >
                {children}
              </ScrollWrapper>
              <Box width={"4px"} sx={{ background: "#5c412a" }}>
                <Box ref={thumbRef} sx={{ position: "relative", background: "#ac8d75", width: "100%" }}></Box>
              </Box>
            </Box>
            {btn && <Box padding={"10px"}>{btn}</Box>}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingTop: topBottomPadding ? topBottomPadding : isDesktop ? "40px" : "24px",
              paddingBottom: topBottomPadding ? topBottomPadding : isDesktop ? "40px" : "24px",
            }}
          >
            <ScrollWrapper
              sx={{
                paddingLeft: leftRightPadding ? leftRightPadding : isDesktop ? "40px" : "24px",
                paddingRight: leftRightPadding ? leftRightPadding : isDesktop ? "40px" : "24px",
              }}
            >
              {children}
            </ScrollWrapper>
            {btn && <Box padding={"10px"}>{btn}</Box>}
          </Box>
        )}
      </Stack>
    </CustomDialog>
  );
};

export default GradientDialog;
