export enum DealTypes {
  LINK = "Link",
  CODE = "Code",
  SLIDER = "Slider",
}

type TFilter = {
  type?: DealTypes;
  types?: DealTypes[];
  tags?: string[];
};

export type TDealsParams = {
  filter?: TFilter;
};

export type TDeal = {
  imageUrl: string;
  imageRedirectUrl: string;
  imageText: string;
  title: string;
  description: string;
  clickOption: string;
  type: DealTypes;
  tags: string;
};

export type TDealResponse = TDeal[];

type TSliderDeal = {
  imageUrl: string;
  clickOption: string;
};
export type TSliderDealResponse = TSliderDeal[];

type TDealTag = {
  _id: string;
  name: string;
};

export type TDealTagsResponse = TDealTag[];
