import { FC, PropsWithChildren } from "react";

import { ButtonProps, Stack } from "@mui/material";

import * as Styled from "./styles";

export type TIconButton = {
  isBigSize?: boolean;
  isTransparent?: boolean;
  isActive?: boolean;
  isFooter?: boolean;
  isCircleHover?: boolean;
  backgroundColor?: string;
  isHeartIcon?: boolean;
  isBottomPlayer?: boolean;
} & PropsWithChildren &
  ButtonProps;

export const IconButton: FC<TIconButton> = ({
  children,
  isBigSize = false,
  isTransparent = false,
  isActive = false,
  isFooter = false,
  isCircleHover = false,
  backgroundColor = "transparent",
  isHeartIcon = false,
  isBottomPlayer = false,
  ...restProps
}) => {
  return (
    <Styled.Button
      $isFooter={isFooter}
      $isBigSize={isBigSize}
      $isTransparent={isTransparent}
      $isActive={isActive}
      $isCircleHover={isCircleHover}
      $backgroundColor={backgroundColor}
      $isHeartIcon={isHeartIcon}
      $isBottomPlayer={isBottomPlayer}
      {...restProps}
    >
      <Stack width="100%" height="100%" alignItems="center" justifyContent="center">
        {children}
      </Stack>
    </Styled.Button>
  );
};
