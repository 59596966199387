import React, { FC, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Stack } from "@mui/material";

import { Text } from "@ui/typography";

import { HEADING_FONT_WEIGHT, TEXT_FONT_WEIGHT } from "@pages/FreeUseTerms/constants";

import BorderStack from "@components/BorderStack";
import { VerticalConnector } from "@components/components";
import CreditText from "@components/CreditText";
import { QuestionFlowDialog } from "@components/QuestionFlowDialog";
import RequireLicense from "@components/RequireLicense";

import { InfoLink } from "@/components/InfoStack/components";
import RestrictionItem from "@/components/RestrictionItem";
import { useIsDesktop } from "@/hooks/layout";

import { dialogLicenseBlockParts, dialogNeverAllowedKeys, LicenseBlock } from "./constants";

type TFreeUseTermsComponent = {
  isPage?: boolean;
  composer?: string;
  songName?: string;
  videoUrl?: string;
  spotifyUrl?: string;
};

const FreeUseTermsSignUpComponent: FC<TFreeUseTermsComponent> = ({
  composer,
  songName,
  videoUrl,
  spotifyUrl,
  isPage = true,
}) => {
  const { t } = useTranslation("termsSignup");

  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [openQuestionFlow, setOpenQuestionFlow] = useState<boolean>(false);

  const isDesktop = useIsDesktop();

  const paddingForBlocks = isDesktop || isPage ? "24px !important" : "20px !important";
  useEffect(() => {
    if (isCopied) {
      const countdownInterval = setInterval(() => {
        setIsCopied(false);
      }, 2000);

      return () => clearInterval(countdownInterval);
    }
  }, [isCopied]);

  const getMainTextHeadingSize = () => {
    if (isPage && isDesktop) {
      return 2.25;
    }
    if (isPage && !isDesktop) {
      return 1.75;
    }
    return 1.5;
  };

  const getCommonTextSize = () => {
    if (!isPage || !isDesktop) {
      return 0.875;
    }

    return 1;
  };

  const getCommonTextHeadingSize = () => {
    if (!isPage || !isDesktop) {
      return 1.125;
    }

    return 1.5;
  };

  const renderLicense = ({ key, route }: LicenseBlock) => (
    <RequireLicense arrowAlign="start">
      <Text size={getCommonTextSize()} isFullWidth textAlign="left" fontWeight={TEXT_FONT_WEIGHT}>
        <Trans
          t={t}
          i18nKey={key}
          components={[<InfoLink underline="hover" href={route ? route : ""} target="_blank" isInheritSize={true} />]}
        />
      </Text>
    </RequireLicense>
  );

  const renderNotAllowed = (key: string) => (
    <RestrictionItem xMarkAlign="start">
      <Text size={getCommonTextSize()} isFullWidth textAlign="left" fontWeight={TEXT_FONT_WEIGHT}>
        <Trans t={t} i18nKey={key} components={[<InfoLink underline="hover" />]} />
      </Text>
    </RestrictionItem>
  );

  return (
    <>
      <Stack width="100%" spacing={isDesktop ? "40px" : "24px"}>
        <Text size={getMainTextHeadingSize()} isHeading isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
          {t("dialog.mainText")}
        </Text>
        <BorderStack innerSx={{ padding: paddingForBlocks }}>
          <Stack width={"100%"} gap={isPage && isDesktop ? "24px" : "16px"} zIndex={5} alignItems={"center"}>
            <Text
              size={getCommonTextHeadingSize()}
              isHeading
              isFullWidth
              textAlign="center"
              fontWeight={TEXT_FONT_WEIGHT}
            >
              {t("dialog.checkQuality.mainText")}
            </Text>
            <Stack width={isPage && isDesktop ? "37.5%" : "100%"} gap={"16px"}>
              <Text size={getCommonTextSize()} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans t={t} i18nKey="dialog.checkQuality.description" components={[]} />
              </Text>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      <VerticalConnector />

      <Stack id="creditRequirement" width="100%" spacing={isDesktop ? "40px" : "24px"}>
        <BorderStack innerSx={{ padding: paddingForBlocks }}>
          <Stack
            width={"100%"}
            gap={isPage && isDesktop ? "24px" : "16px"}
            alignItems="center"
            justifyContent="center"
            zIndex={5}
          >
            <Text
              size={getCommonTextHeadingSize()}
              isHeading
              isFullWidth
              textAlign="center"
              fontWeight={TEXT_FONT_WEIGHT}
            >
              {t("dialog.credit.mainText")}
            </Text>
            <Stack width={isPage && isDesktop ? "45%" : "100%"} gap={"16px"}>
              <Text size={getCommonTextSize()} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans t={t} i18nKey="dialog.credit.description" />
              </Text>
            </Stack>

            <Stack spacing={isDesktop ? "16px" : "8px"} width="100%" alignItems="center" justifyContent="center">
              <Stack width={"100%"}>
                <Stack width="100%" height="100%" alignItems="center" justifyContent="center" zIndex={5}>
                  <BorderStack
                    withoutBorder={true}
                    outerSx={{ width: `fit-content !important` }}
                    innerSx={{ padding: `${isDesktop ? "24px 64px" : "16px"} !important` }}
                  >
                    <Stack alignItems="center" justifyContent="center" gap={isPage && isDesktop ? "24px" : "16px"}>
                      <Text
                        size={!isPage || !isDesktop ? 0.75 : 1}
                        isFullWidth
                        textAlign="center"
                        fontWeight={TEXT_FONT_WEIGHT}
                      >
                        <CreditText
                          composer={composer}
                          songName={songName}
                          videoUrl={videoUrl}
                          spotifyUrl={spotifyUrl}
                          sx={{ fontSize: "inherit !important" }}
                        />
                      </Text>
                    </Stack>
                  </BorderStack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      <VerticalConnector />

      <Stack width="100%">
        <BorderStack innerSx={{ padding: paddingForBlocks }}>
          <Stack
            width="100%"
            gap={isPage && isDesktop ? "24px" : "16px"}
            alignItems="center"
            justifyContent="center"
            zIndex={5}
          >
            <Text
              size={getCommonTextHeadingSize()}
              isHeading
              isFullWidth
              textAlign="center"
              fontWeight={TEXT_FONT_WEIGHT}
            >
              <Trans t={t} i18nKey="dialog.content.mainText" />
            </Text>
            <Stack width={isPage && isDesktop ? "85%" : "100%"}>
              <Text size={getCommonTextSize()} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans
                  t={t}
                  i18nKey="dialog.content.description"
                  components={[
                    <InfoLink
                      underline="hover"
                      href={"/contact-licensing/contact"}
                      target="_blank"
                      isInheritSize={true}
                    />,
                  ]}
                />
              </Text>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      <VerticalConnector />

      <Stack width="100%">
        <BorderStack innerSx={{ padding: paddingForBlocks }}>
          <Stack width={"100%"} gap={isPage && isDesktop ? "24px" : "16px"} zIndex={5} alignItems={"center"}>
            <Text
              size={getCommonTextHeadingSize()}
              isHeading
              isFullWidth
              textAlign="center"
              fontWeight={TEXT_FONT_WEIGHT}
            >
              {t("dialog.donation.mainText")}
            </Text>
            <Stack width={isPage && isDesktop ? "72.5%" : "100%"}>
              <Text size={getCommonTextSize()} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans t={t} i18nKey="dialog.donation.description" />
              </Text>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>
      {/* <>------------------------------------------------------------</> */}

      <Stack id="usesThatRequireLicense" width="100%" sx={{ margin: "40px 0 0 0" }}>
        <BorderStack
          withoutBorder
          outerSx={{
            background: "linear-gradient(0deg, rgba(59, 45, 33, 0.375) 0%, rgba(58, 44, 36, 0.75) 100%)",
            borderRadius: "16px",
          }}
          innerSx={{ padding: paddingForBlocks }}
        >
          <Stack width="100%" spacing={"16px"} alignItems="center" justifyContent="center" zIndex={5}>
            <Text
              size={getCommonTextHeadingSize()}
              isHeading
              isFullWidth
              textAlign="center"
              fontWeight={HEADING_FONT_WEIGHT}
            >
              {t("dialog.licenseRequire.mainText")}
            </Text>

            <Stack width={isPage && isDesktop ? "90%" : "100%"} gap={isPage && isDesktop ? "24px" : "16px"}>
              {dialogLicenseBlockParts.map(renderLicense)}
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      <Stack width="100%" sx={{ margin: "40px 0 0 0" }}>
        <BorderStack
          withoutBorder
          outerSx={{
            background: "linear-gradient(0deg, rgba(59, 45, 33, 0.375) 0%, rgba(58, 44, 36, 0.75) 100%)",
            borderRadius: "16px",
          }}
          innerSx={{ padding: paddingForBlocks }}
        >
          <Stack width="100%" spacing={"24px"} alignItems="center" justifyContent="center" zIndex={5}>
            <Text
              size={getCommonTextHeadingSize()}
              isHeading
              isFullWidth
              textAlign="center"
              fontWeight={HEADING_FONT_WEIGHT}
            >
              {t("dialog.neverAllowed.mainText")}
            </Text>

            <Stack width={isPage && isDesktop ? "90%" : "100%"} gap={isPage && isDesktop ? "24px" : "8px"}>
              {dialogNeverAllowedKeys.map(renderNotAllowed)}
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      <QuestionFlowDialog open={openQuestionFlow} onClose={() => setOpenQuestionFlow(false)} />
    </>
  );
};

export default FreeUseTermsSignUpComponent;
