import React, { ChangeEvent, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Checkbox, FormControl, FormControlLabel, Stack } from "@mui/material";

import { Button as GradientButton } from "@ui/index";

import { AdditionalTypography } from "@pages/Profile/components";

import { useIsDesktop } from "@hooks/layout";

import { updateMy } from "@store/actions/user";
import { useAppDispatchUnwrap } from "@store/hooks";

import CustomTooltip from "@components/CustomTooltip";
import { MainTextTypography } from "@components/RecoveryDialog/components";
import Stepper from "@components/SignUpDialog/components/Stepper";
import { ESignUp } from "@layouts/SecondRootLayout";

const Newsletter = () => {
  const { t } = useTranslation("signup", {
    keyPrefix: "newsletter",
  });

  const isDesktop = useIsDesktop();

  const dispatch = useAppDispatchUnwrap();

  const [isReleaseNewsletterChecked, setIsReleaseNewsletterChecked] = useState<boolean>(false);
  const [isGeneralNewsletterChecked, setIsGeneralNewsletterChecked] = useState<boolean>(false);

  const handleChangeRelease = (event: ChangeEvent<HTMLInputElement>) => {
    setIsReleaseNewsletterChecked(event.target.checked);
  };

  const handleChangeGeneral = (event: ChangeEvent<HTMLInputElement>) => {
    setIsGeneralNewsletterChecked(event.target.checked);
  };

  return (
    <Stack spacing={isDesktop ? "32px" : "24px"} width="100%" justifyContent="center" alignItems="center">
      <Stepper activeStep={ESignUp.Newsletter} />
      <Stack spacing={isDesktop ? "24px" : "16px"} width="100%">
        <Stack spacing="16px" height="100%" width="100%" alignItems={"center"}>
          <MainTextTypography>{t("mainText")}</MainTextTypography>
        </Stack>

        <FormControl fullWidth>
          <Stack gap={"16px"}>
            <Stack>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChangeRelease}
                    checked={isReleaseNewsletterChecked}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: isDesktop ? "1.5rem" : "2rem",
                      },
                    }}
                  />
                }
                label={
                  <Trans
                    t={t}
                    i18nKey="checkBoxLabelRelease"
                    components={[<span style={{ color: "#FFDCA8", fontWeight: "400 !important" }} />]}
                  />
                }
                labelPlacement="end"
                sx={{ margin: "0", textAlign: "start" }}
              />
              <AdditionalTypography sx={{ "&&": { padding: 0, margin: "0 0 0 40px" } }}>
                {t("releaseDescription")}
              </AdditionalTypography>
            </Stack>

            <Stack>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChangeGeneral}
                    checked={isGeneralNewsletterChecked}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: isDesktop ? "1.5rem" : "2rem",
                      },
                    }}
                  />
                }
                label={
                  <Trans
                    t={t}
                    i18nKey="checkBoxLabelGeneral"
                    components={[<span style={{ fontWeight: "400 !important" }} />]}
                  />
                }
                labelPlacement="end"
                sx={{ margin: "0", textAlign: "start" }}
              />
              <AdditionalTypography sx={{ "&&": { padding: 0, margin: "0 0 0 40px" } }}>
                {t("generalDescription")}
              </AdditionalTypography>
            </Stack>
          </Stack>
        </FormControl>
      </Stack>

      <Stack width="100%" zIndex={10}>
        <CustomTooltip disableHoverListener={isReleaseNewsletterChecked} title={<Trans t={t} i18nKey="toolTip" />}>
          <GradientButton
            isDisabled={!isReleaseNewsletterChecked}
            onClick={async () => {
              await dispatch(
                updateMy({
                  hasNewsletter: true,
                  hasGeneralNewsletter: isGeneralNewsletterChecked,
                }),
              );
            }}
            isFullWidth
          >
            {t("buttonText")}
          </GradientButton>
        </CustomTooltip>
      </Stack>
    </Stack>
  );
};

export default Newsletter;
