import React, { Suspense } from "react";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { ThemeProvider } from "@mui/material";

import { setupResponseInterceptor } from "@store/middleware/api";
import { store } from "@store/store";

import { addMaximumScaleToMetaViewport, isIOS } from "@components/BottomPlayer/components/Player/helpers";
import { PlayerLayout } from "@layouts/PlayerLayout";
import theme from "@theme/theme";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { LoaderComponent } from "@/ui-kit";

import routes from "./routes";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "react-toastify/dist/ReactToastify.min.css";

setupResponseInterceptor(store);

function App() {
  const content = useRoutes(routes);

  if (isIOS()) {
    addMaximumScaleToMetaViewport();
  }

  return (
    <CookiesProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CookiesProvider>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <Suspense fallback={<LoaderComponent />}>
                <PlayerLayout>{content}</PlayerLayout>
                <ToastContainer />
              </Suspense>
            </QueryParamProvider>
          </CookiesProvider>
        </ThemeProvider>
      </Provider>
    </CookiesProvider>
  );
}

export default App;
