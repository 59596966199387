import { Pagination } from "@mui/material";

import { isMobile } from "@hooks/layout";

import styled from "styled-components";

export const Wrapper = styled(Pagination)`
  && {
    font-family: Kantumruy Pro;
    font-size: 1rem;

    .MuiButtonBase-root {
      width: 48px;
      height: 48px;
    }

    .MuiSvgIcon-root {
      scale: 1.3;
    }

    & .MuiPaginationItem-page:not(.Mui-selected) {
      color: #e3bda0;
      border: 1px solid #ffdca8;
      background-color: rgb(75 55 39 / 50%);
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        color: #fbf4ec;
        background-color: #4b3727;
      }
    }

    & .Mui-selected {
      color: #fbf4ec;
      background-color: #4b3727;
    }

    .MuiPaginationItem-icon {
      fill: #ffdca8;
    }

    .MuiPaginationItem-text {
      font-size: 1rem;
    }

    .Mui-disabled {
      .MuiPaginationItem-icon {
        fill: #4b3727;
      }

      opacity: 0.5;
    }

    .MuiPaginationItem-ellipsis {
      font-family: "Kantumruy Pro";
      font-size: 1rem;
      color: #fff;
      transform: translateY(60%);

      ${isMobile} {
        font-size: 1rem;
        transform: translateY(60%);
      }
    }

    ${isMobile} {
      .MuiButtonBase-root {
        width: 42px;
        height: 42px;
      }
    }

    .MuiPagination-ul {
      justify-self: center;

      @media only screen and (max-width: 450px) {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;

        li {
          display: flex;
          flex-grow: 1;

          div {
            width: 100%;
          }
          button {
            width: auto;
            flex-grow: 1;
            aspect-ratio: 1 / 1;
            height: auto;
          }
        }
      }
    }
  }
`;
