import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Stack } from "@mui/material";

import { Button as GradientButton } from "@ui/index";

import { Formik } from "formik";
import { object, string } from "yup";

import FormikInput from "@/components/FormikInput";
import { useIsDesktop } from "@/hooks/layout";
import { IError } from "@/models/inner-models";
import { newsletter } from "@/store/actions/newsletter";
import { useAppDispatchUnwrap } from "@/store/hooks";

const validationSchema = (t: (key: string) => string) => {
  return object({
    firstName: string().required(t("fieldRequired")),
    email: string().email(t("emailValidation")).required(t("fieldRequired")),
  });
};

interface IForm {
  handleOpenDialog: () => void;
}

const Form: FC<IForm> = ({ handleOpenDialog }) => {
  const { t } = useTranslation("homePage", { keyPrefix: "newsletter" });
  const { t: tValidation } = useTranslation("validation");

  const isDesktop = useIsDesktop();

  const dispatch = useAppDispatchUnwrap();

  const initialValues = useMemo(
    () => ({
      firstName: "",
      email: "",
      fullNewsletter: false,
    }),
    [isDesktop],
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={() => validationSchema(tValidation)}
      onSubmit={async (values, { setErrors, resetForm }) => {
        try {
          await dispatch(newsletter(values));
          handleOpenDialog();
          resetForm();
        } catch (err) {
          const message = (err as IError).message;
          setErrors({ email: message });
        }
      }}
    >
      {({ handleSubmit, isSubmitting, dirty, isValid }) => (
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Stack
            alignItems="center"
            justifyContent={{ xs: "center", lg: "start" }}
            width="100%"
            direction={isDesktop ? "row" : "column"}
            spacing="16px"
          >
            <Stack spacing={isDesktop ? "16px" : "24px"} width="100%" direction={isDesktop ? "row" : "column"}>
              <FormikInput
                fieldName="firstName"
                label={t("firstName")}
                placeholder={t("firstName") ?? ""}
                autoComplete="name"
              />

              <FormikInput fieldName="email" label={t("email")} placeholder={t("email") ?? ""} autoComplete="email" />
            </Stack>

            <Stack
              spacing="16px"
              width={{ xs: "100%", md: "auto" }}
              alignItems={isDesktop ? "start" : "center"}
              justifyContent="center"
            >
              <GradientButton
                type="submit"
                isDisabled={!(isValid && dirty && !isSubmitting)}
                isFullWidth={!isDesktop}
                isBigButton
              >
                Send
              </GradientButton>
            </Stack>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

export default Form;
