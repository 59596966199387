export const popover = {
  pointerEvents: "none",
  elevation: 0,
  sx: {
    width: "320px",
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    border: "double 1px transparent",
    borderTop: "0",
    borderRadius: "0",
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    backgroundClip: "content-box, border-box",
    backgroundImage:
      "linear-gradient(#4b3727, #4b3727), linear-gradient(93deg, #BA905C 0.82%, #FFD29B 33.73%, #FFE0B1 47.65%, #FFD29B 61.53%, #BA905C 99.48%)",
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: 1,
      mr: 1,
    },
  },
};
