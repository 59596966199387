import React, { FC, PropsWithChildren } from "react";

import { AvatarProps, Stack } from "@mui/material";

import * as Styled from "./styles";
import { getComposerAvatar } from "./utils";

type TAvatar = AvatarProps & {
  isSmall?: boolean;
  isGroup?: boolean;
  doesNeedAlignment?: boolean;
  composerName?: string;
  isSmallDesktop?: boolean;
};

type TAvatarGroup = TAvatar & PropsWithChildren;

const AvatarWrapper: FC<TAvatar> = ({ composerName, isSmall, ...restProps }) => (
  <Styled.AvatarWrapper
    src={getComposerAvatar(composerName)}
    imgProps={{ loading: "lazy" }}
    $isSmall={isSmall}
    {...restProps}
  />
);

export const Avatar: FC<TAvatarGroup> = (props) => {
  const {
    children,
    doesNeedAlignment,
    isSmall = false,
    isGroup = false,
    composerName,
    isSmallDesktop,
    sx,
    ...restProps
  } = props;
  return isGroup ? (
    <AvatarWrapper composerName={composerName} isSmall={isSmall} {...restProps} />
  ) : (
    <Stack
      direction="row"
      spacing="8px"
      sx={{
        alignItems: doesNeedAlignment ? "flex-start" : "center",
        justifyContent: isSmallDesktop ? "flex-end" : "",
        ...sx,
      }}
    >
      <AvatarWrapper
        composerName={composerName}
        isSmall={isSmall}
        {...restProps}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      />
      {children}
    </Stack>
  );
};
