import { Box, Typography } from "@mui/material";

import { MenuItemWrapper as MIW, MenuWrapper as MW } from "@components/SongMenu/styles";
import styled from "styled-components";

export const IconButtonTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.header};
  font-family: Kaisei Opti;

  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;

  letter-spacing: 0.01rem;
  font-weight: 400;
  text-transform: uppercase;
`;

export const MenuItemWrapper = styled(MIW)`
  color: ${({ theme }) => theme.palette.textColor.common};
  font-size: 0.875rem;
  padding: 0;
  min-height: fit-content;
`;

export const ImageWrapper = styled(Box)`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
  }
`;
export const MenuItemImage = styled("img")`
  object-fit: cover;
`;

export const MenuWrapper = styled(MW)`
  && {
    padding: 0;
  }
`;

export const ItemsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 16px;
`;
